import {
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  GetRowIdParams,
  RedoEndedEvent,
  UndoEndedEvent,
} from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { httpPatch } from 'common/services'
import { Button } from 'inspinia-template'
import { forwardRef, useCallback, useRef, useState } from 'react'
import { backendURL } from '../services/api'
import { AgGridWithClipboard } from './AgGridWithClipboard'
import { toast } from 'react-toastify'
import { useLocalStorage } from 'common/hooks'

export type GridChangeLog = {
  id: number
  field: string
  newValue: string
  oldValue: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any
  fieldTitle: string
}

export const AgGridWithChangeTracking = forwardRef<AgGridReact, AgGridReactProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gridRef = ref as any

  const pendingRef = useRef<HTMLParagraphElement>(null)

  const [pendingLog, setPendingLog] = useState<GridChangeLog[]>([])

  const [storedData, setStoredData] = useLocalStorage('pendingLog', [])

  const applyChanges = () => {
    const remaining = [] as GridChangeLog[]

    pendingLog.forEach(async (value, key) => {
      const resp = await httpPatch(backendURL + '/zuma/products/' + value.id, {
        id: value.id,
        field: value.field,
        newValue: value.newValue,
        oldValue: value.oldValue,
      }).catch((error) => {
        return error
      })

      if (resp && resp.status == 200) {
        toast.success('SKU ' + value.id + ': campo ' + value.fieldTitle + ' alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: true,
          theme: 'colored',
          onClose: () => {},
        })

        value.event.colDef.cellClass = ''

        gridRef.current!.api.refreshCells({
          columns: [value.field],
          rowNodes: [value.event.node],
          force: true,
        })
      } else {
        remaining.push(value)

        toast.error('SKU ' + value.id + ' falhou ao aplicar atualizações', {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: true,
          theme: 'colored',
          onClose: () => {},
        })
      }

      if (key == pendingLog.length - 1) {
        setPendingLog(remaining)
        let pChanged = {}
        remaining.forEach((changed) => {
          pChanged = {
            field: changed.field,
            id: changed.id,
            newValue: changed.newValue,
            oldValue: changed.oldValue,
          }
        })
        setStoredData([...storedData, pChanged])
      }
    })
  }

  const discardChanges = () => {
    pendingLog.forEach(() => {
      undo()
    })
    setPendingLog([])
    setStoredData([])
  }

  const markAsDirty = useCallback(
    (event: CellValueChangedEvent) => {
      // console.log('mark as dirty')

      event.colDef.cellClass = (p) => (p.node.id === event.node.id && event.source != 'undo' ? 'ag-cell-dirty' : '')

      if (event.column) {
        //useUpdate()
      }

      gridRef.current!.api.refreshCells({
        columns: [event.column.getId()],
        rowNodes: [event.node],
        force: true, // without this line, the cell style is not refreshed at the first time
      })
    },
    [gridRef],
  )

  const redo = useCallback(() => {
    gridRef.current!.api.redoCellEditing()
  }, [gridRef])

  const undo = useCallback(() => {
    gridRef.current!.api.undoCellEditing()
  }, [gridRef])

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      markAsDirty(event)

      console.log('cellValueChanged', event)
      const undoSize = event.api.getCurrentUndoSize()
      setValue('#undoInput', undoSize)
      disable('#undoBtn', undoSize < 1)
      const redoSize = event.api.getCurrentRedoSize()
      setValue('#redoInput', redoSize)
      disable('#redoBtn', redoSize < 1)

      if (event.source === 'redo') {
        console.log('to aqui')
        if (event.colDef.field) {
          const fieldName = event.colDef.field

          const changed: GridChangeLog = {
            id: event.data.id,
            field: fieldName,
            fieldTitle: event.colDef.headerName || '',
            newValue: event.value.toString(),
            oldValue: event.oldValue.toString(),
            event: event,
          }
          const pChanged = {
            field: changed.field,
            id: changed.id,
            newValue: changed.newValue,
            oldValue: changed.oldValue,
          }

          if (pendingLog === undefined) {
            setPendingLog([changed])
            setStoredData([pChanged])
          } else {
            setPendingLog([...pendingLog, changed])
            setStoredData([...storedData, pChanged])
          }
          console.log(pendingLog)
          if (pendingRef.current) {
            pendingRef.current.textContent = pendingLog.length.toString()

            if (
              pendingRef.current.parentElement &&
              pendingRef.current.parentElement.parentElement &&
              pendingLog.length > 0
            ) {
              pendingRef.current.parentElement.parentElement.style.visibility = 'visible'
            }
          }
        }
      } else {
        if (event.colDef.field) {
          const fieldName = event.colDef.field
          const changed: GridChangeLog = {
            id: event.data.id,
            field: fieldName,
            fieldTitle: event.colDef.headerName || '',
            newValue: event.value?.toString(),
            oldValue: event.oldValue?.toString(),
            event: event,
          }
          const pChanged = {
            field: changed.field,
            id: changed.id,
            newValue: changed.newValue,
            oldValue: changed.oldValue,
          }

          if (pendingLog === undefined) {
            setPendingLog([changed])
            setStoredData([...storedData, pChanged])
          } else {
            setPendingLog([...pendingLog, changed])
            setStoredData([...storedData, pChanged])
          }
          console.log(changed)
        }
      }
    },
    [markAsDirty, pendingLog, setStoredData, storedData],
  )

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    if (!event) {
      console.log(event)
    }
    // if (event.valueChanged && event.colDef.field) {
    //   const fieldName = event.colDef.field
    //   const changed: GridChangeLog = {
    //     id: event.data.id,
    //     field: fieldName,
    //     newValue: event.value.toString(),
    //     oldValue: event.oldValue,
    //   }
    //   if (pendingLog === undefined) {
    //     setPendingLog([changed])
    //   } else {
    //     setPendingLog([...pendingLog, changed])
    //   }
    //   console.log(changed)
    // }
  }

  const onRedoEnded = (event: RedoEndedEvent) => {
    console.log('redoEnded', event)
  }

  const onUndoEnded = (event: UndoEndedEvent) => {
    if (event.operationPerformed) {
      pendingLog.pop()
      setPendingLog(pendingLog)
      storedData.pop()
      setStoredData(storedData)

      if (pendingRef.current) {
        pendingRef.current.textContent = pendingLog.length.toString()

        if (
          pendingRef.current.parentElement &&
          pendingRef.current.parentElement.parentElement &&
          pendingLog.length == 0
        ) {
          pendingRef.current.parentElement.parentElement.style.visibility = 'hidden'
        }
      }
    }
  }

  function disable(id: string, disabled: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (document.querySelector(id)) (document.querySelector(id) as any).disabled = disabled
  }

  function setValue(id: string, value: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (document.querySelector(id)) (document.querySelector(id) as any).value = value
  }

  const onFirstDataRendered = useCallback(() => {
    setValue('#undoInput', 0)
    disable('#undoInput', true)
    disable('#undoBtn', true)
    setValue('#redoInput', 0)
    disable('#redoInput', true)
    disable('#redoBtn', true)
  }, [])

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.id
  }, [])

  const setColumnValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (rowId: string, colName: string, value: any) => {
      const rowNode = gridRef.current!.api.getRowNode(rowId)!
      rowNode.setDataValue(colName, value)
    },
    [gridRef],
  )

  const applyStored = () => {
    const values = storedData
    console.log(values)
    setStoredData([])
    values.forEach((value) => {
      console.log('trying to update id ' + value.id)
      setColumnValue(value.id.toString(), value.field, value.newValue)
      setTimeout(() => {}, 1000)
    })
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <span className="button-group">
          <label>Available Undo's</label>
          <input id="undoInput" className="undo-redo-input" />
          <label>Available Redo's</label>
          <input id="redoInput" className="undo-redo-input" />
          <button id="undoBtn" className="undo-btn" onClick={undo}>
            Undo
          </button>
          <button id="redoBtn" className="redo-btn" onClick={redo}>
            Redo
          </button>
        </span>
      </div>
      {/* <Button
        className="m-l-md"
        label="Aplicar alterações pendentes"
        size="small"
        variant="primary"
        onClick={applyStored}
      /> */}
      <div className="m-t-sm text-right" style={{ visibility: pendingLog.length > 0 ? 'visible' : 'hidden' }}>
        <span>
          <p className="inline" ref={pendingRef}>
            {pendingLog.length}
          </p>{' '}
          alterações pendentes!
        </span>
        <Button className="m-l-md" label="Salvar alterações" size="small" variant="primary" onClick={applyChanges} />
        <Button className="m-l-xs" label="Descartar" size="small" variant="secondary" onClick={discardChanges} />
      </div>
      <AgGridWithClipboard
        {...props}
        getRowId={getRowId}
        ref={ref}
        onCellEditingStopped={onCellEditingStopped}
        onCellValueChanged={onCellValueChanged}
        onFirstDataRendered={onFirstDataRendered}
        onRedoEnded={onRedoEnded}
        onUndoEnded={onUndoEnded}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={50}
      />
    </>
  )
})
